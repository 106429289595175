import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useRedux } from '../../hooks'
import { setFormData, setSuccess } from '../../store/actions/contactform'
import { REGEXP_EMAIL } from '../../constants/RegExp'
import { CONTACT_FORM_FIELDS as FIELDS } from '../../constants/Languages'

import './ContactForm.scss'

const ContactForm = ({ lang }) => {
  const [state, dispatch] = useRedux(s => s.contactform)

  const submitHandler = useCallback(() => {
    const data = {
      name: state.name,
      email: state.email,
      phone: `${state.code}${state.phone}`,
      skype: state.skype,
      message: state.message
    }

    const { name, email, message } = data

    const formData = new FormData()

    for (let el in data) {
      formData.append(el, data[el])
    }

    if (name && message && email.match(REGEXP_EMAIL)) {
      fetch('/sendMail', {
        method: 'POST',
        body: formData
      })
        .then(res => {
          res.text()
          dispatch(setSuccess(true))
        })
        .catch(e => console.error(e))
    }
  })

  const formHandler = useCallback(field => e => {
    dispatch(setFormData({ [field]: e.target.value }))
  })

  return (
    <>
      {!state.success ? (
        <form
          className='contact-form'
          onSubmit={e => {
            e.preventDefault()
            submitHandler
          }}
        >
          <div className='contact-form-block'>
            <input
              required
              type='text'
              placeholder={`${FIELDS[lang].name}`}
              name=''
              id='inputName'
              onChange={formHandler('name')}
            />
          </div>
          <div className='contact-form-block'>
            <input
              required
              type='email'
              placeholder={`${FIELDS[lang].email}`}
              name=''
              id='inputMail'
              onChange={formHandler('email')}
            />
          </div>
          <div className='contact-form-block'>
            <input
              type='text'
              placeholder={`${FIELDS[lang].phoneCode}`}
              name=''
              id='inputCode'
              onChange={formHandler('code')}
            />
            <input
              type='text'
              placeholder={`${FIELDS[lang].phone}`}
              name=''
              id='inputPhone'
              onChange={formHandler('phone')}
            />
          </div>
          <div className='contact-form-block'>
            <input
              type='text'
              placeholder={`${FIELDS[lang].skype}`}
              name=''
              id=''
              onChange={formHandler('skype')}
            />
          </div>
          <div className='contact-form-block'>
            <textarea
              required
              placeholder={`${FIELDS[lang].message}`}
              name=''
              id=''
              onChange={formHandler('message')}
            />
          </div>
          <button className='btn' onClick={submitHandler} type='submit'>
            {FIELDS[lang].btnTxt}
          </button>
        </form>
      ) : (
        <>
          <form className='contact-form'>
            <div className='contact-form-title'>
              {FIELDS[lang].messageConfirm}
            </div>
            <button className='btn' type='submit'>
              {FIELDS[lang].btnConfirmTxt}
            </button>
          </form>
        </>
      )}
    </>
  )
}

ContactForm.propTypes = {
  lang: PropTypes.string.isRequired
}

export default ContactForm
